//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown, sysConfigAdd } from '@/api/modular/system/configManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      formLoading: true,
      groupCodeList: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    add () {
      this.visible = true
      this.sysDictTypeDropDown()
    },

    /**
     * 獲取所屬分類
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'consts_type' }).then((res) => {
        this.groupCodeList = res.data
        this.formLoading = false
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysConfigAdd(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('新增成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
